body {
	background-color: #fff;
	font-family: Raleway, sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Playfair Display', sans-serif;
	color: #1f5390;
	font-size: 2.75rem;
	line-height: 3.25rem;
	font-weight: 700;
	letter-spacing: 0rem;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #1f5390;
	font-size: 1.85rem;
	line-height: 2.35rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Raleway, sans-serif;
	color: #1f5390;
	font-size: 1.35rem;
	line-height: 1.9rem;
	font-weight: 600;
	text-transform: none;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #1f5390;
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #1f5390;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.25rem;
	color: #1f5390;
	font-size: 0.85rem;
	line-height: 1.3rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-family: Raleway, sans-serif;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 40px;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

li {
	margin-bottom: 0.25rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 1.5rem;
	border-right: 8px solid #25aaa3;
	border-left: 8px solid #25aaa3;
	font-size: 1.15rem;
	line-height: 1.9rem;
	font-weight: 500;
}

.ui-form-field.a-button button,
.ui-form-field.a-button button,
.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 6px 0.75rem;
	border: 2px solid #4cbeb8;
	border-radius: 5px;
	background-color: #4cbeb8;
	color: #000;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.ui-form-field.a-button button,
.ui-form-field.a-button button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 6px 0.75rem !important;
	border: 2px solid #4cbeb8 !important;
}

.main-content a.primary,
.left-sidebar-custom a.primary,
.right-side-col a.primary,
.main-content a.secondary,
.left-sidebar-custom a.secondary,
.right-side-col a.secondary,
.main-content a.tertiary,
.left-sidebar-custom a.tertiary,
.right-side-col a.tertiary {
	color: #000;
}

.ui-form-field.a-button button:hover,
.ui-form-field.a-button button:hover,
.button:hover,
a.primary:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	border-color: #4cbeb8 !important;
	background-color: #fff !important;
	color: #00283b;
}

.main-content a.primary:hover,
.left-sidebar-custom a.primary:hover,
.right-side-col a.primary:hover,
.main-content a.tertiary:hover,
.left-sidebar-custom a.tertiary:hover,
.right-side-col a.tertiary:hover {
	color: #00283b;
}

.ui-form-field.a-button button,
.button.secondary {
	border: 2px solid #ccc;
	background-color: #ccc;
	color: #000;
	text-align: center;
}

.ui-form-field.a-button button,
.secondary,
button[type="submit"].secondary {
	border: 2px solid #ccc !important;
	background-color: #ccc !important;
}

.ui-form-field.a-button button:hover,
.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	border-color: #adadad !important;
	background-color: #fff !important;
	color: #000;
}

.main-content a.secondary:hover,
.left-sidebar-custom a.secondary:hover,
.right-side-col a.secondary:hover {
	color: #000;
}

.button.more-button {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: inline-block;
	height: 40px;
	min-width: 0px;
	margin-top: 1rem;
	margin-right: auto;
	margin-left: 2rem;
	padding-top: 6px;
	padding-right: 1rem;
	padding-left: 1rem;
	border: 2px solid #4cbeb8;
	background-color: #4cbeb8;
}

.button.more-button:hover {
	border: 2px solid #4cbeb8;
	background-color: #fff;
	color: #000;
}

.button.section-1-cta-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 40px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.75rem;
	padding-top: 6px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 2px solid #4cbeb8;
	background-color: #4cbeb8;
	color: #000;
	letter-spacing: 0.02rem;
}

.button.section-1-cta-button:hover {
	background-color: #fff;
}

.button.todayfree {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 350px;
	min-width: 0px;
	margin-top: 2.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 2px solid #3d9aa1;
	background-color: #aee4e1;
	box-shadow: 7px 7px 40px 0 rgba(0, 0, 0, 0.2);
	color: #000;
	text-align: center;
}

.button.todayfree:hover {
	border-color: #4cbeb8;
	background-color: #fff;
	box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.4);
}

.main-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.button-styles {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.container-custom {
	display: block;
	max-width: 1364px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
}

.container-custom.home-container {
	max-width: 1600px;
}

.container-custom.home-container.row-2-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.container-custom.last-row-container {
	position: relative;
	max-width: 1268px;
	padding: 4rem 2rem 4.5rem;
	background-color: hsla(0, 0%, 100%, 0.9);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.3);
}

.container-custom.top-nav-cont {
	position: relative;
	z-index: 1001;
	width: 50%;
	max-width: 1300px;
	margin-top: 0.5rem;
	padding-right: 1.5rem;
	float: right;
}

.container-custom.inside-page-container {
	position: relative;
	max-width: 1270px;
	padding-right: 0rem;
	padding-left: 0rem;
}

.breadcrumbs-container {
	max-width: 1271px;
	padding-bottom: 1rem;
	padding-left: 0.125rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.right-side-col {
	width: 20%;
	max-width: 250px;
	min-width: 170px;
	margin-left: 3vw;
	padding-top: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	padding-bottom: 0.15rem;
	border-bottom: 1px solid #d6d2c6;
	color: #3d9aa1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.right-side-col label,
.right-side-col td,
.right-side-col p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	padding-bottom: 0.15rem;
	border-bottom: 1px solid #d6d2c6;
	color: #3d9aa1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.right-side-col h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	padding-bottom: 0.15rem;
	border-bottom: 1px solid #d6d2c6;
	color: #3d9aa1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.logo {
	display: block;
	height: 160px;
}

.logo.styles-page-logo {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 2px;
	margin-left: 2px;
	padding: 1.25rem 1rem;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Raleway, sans-serif;
	color: #000;
	font-size: 0.88rem;
	font-weight: 500;
	text-decoration: none;
}

.main-nav-link.active,
.main-nav-link:hover {
	color: #3d9aa1;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.main-nav-link.main-nav-dd-link {
	padding-right: 2.25rem;
}

.main-nav-link.main-nav-dd-link.w--current {
	color: #3d9aa1;
}

.dropdown-list {
	position: absolute;
	display: none;
	overflow: visible;
	height: 0px;
	max-width: none;
	min-width: 200px;
	padding: 0.3rem 0rem 0.5rem;
	background-color: transparent;
	opacity: 0;
	text-align: left;
}

.home-content-section {
	display: block;
	padding: 4vw 3vw;
	background-color: #fff;
}

.home-content-section.row-2-section {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding: 0vw 2rem 4.5rem;
	float: none;
	border-top: 4px solid #4cbeb8;
	box-shadow: 0 -4px 40px 0 rgba(0, 0, 0, 0.2);
}

.home-content-section.last-row {
	position: relative;
	margin-bottom: 0px;
	padding-top: 4.5rem;
	padding-bottom: 4.5rem;
	background-color: transparent;
	background-image: none;
}

.home-content-section.fixed-image {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
	padding-top: 4.5rem;
	padding-bottom: 4.5rem;
	background-position: 0px 0px, 0px 0px, 0px 100%;
	background-size: 90px, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, scroll;
}

.home-content-section.row-1-section {
	position: relative;
	display: block;
	overflow: hidden;
	height: 800px;
	padding: 0vw 2rem;
}

.overlay-1-image-1 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.footer-section {
	background-color: #136970;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-right: 2rem;
	margin-bottom: 0rem;
	opacity: 0.65;
	text-decoration: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.footer-grip-link:hover {
	opacity: 1;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	line-height: 0;
}

.copyright-paragraph {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	float: left;
	opacity: 0.75;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.social-media-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 2.5rem;
	margin-top: 0.25rem;
	margin-bottom: 2rem;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 38px;
	margin-right: 0.4rem;
	margin-left: 0.4rem;
	padding: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 50%;
	background-color: #00282b;
	-webkit-transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block:hover {
	border: 2px solid #00282b;
	background-color: #4cbeb8;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.social-media-link-block.first-social-link {
	margin-left: 0rem;
	padding: 0.25rem;
}

.social-media-link-block.first-social-link:hover {
	border-color: #00282b;
	background-color: #58c2bc;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: none;
}

.home-content-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: 1400px;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.home-content-wrapper.signoff-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1400px;
	padding-top: 1.5rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cta-content {
	overflow: hidden;
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.mobile-menu-arrow {
	display: none;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.intro_paragraph,
.intro-paragraph {
	display: block;
	color: #636363;
	font-size: 1.3rem;
	line-height: 1.9rem;
	font-weight: 500;
}

.header-link {
	display: inline;
	border-bottom: 1px dotted #3d9aa1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #3d9aa1;
	line-height: 1.3em;
	text-decoration: none;
}

.header-link:hover {
	border-bottom-color: transparent;
	background-color: hsla(0, 0%, 68%, 0.3);
	color: #1f5390;
}

.breadcrumbs-list {
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	padding-left: 0px;
	color: #00283b;
	font-size: 0.75rem;
	line-height: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.01rem;
}

.breadcrumbs-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	float: left;
	border-right: 1px solid #bdbdbd;
}

.breadcrumbs-list-item.current {
	padding-right: 0rem;
	border-right-style: none;
	color: #636363;
}

.breadcrumbs-link {
	border-bottom: 1px dotted #85c1f1;
	color: #636363;
	text-decoration: none;
}

.breadcrumbs-link:hover {
	border-bottom-color: transparent;
	color: #000;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #25aaa3;
	background-color: rgba(17, 185, 165, 0.25);
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
}

.screen-message-text {
	font-weight: 500;
}

.main-content a,
.left-sidebar-custom a,
.right-side-col a,
.text-link {
	border-bottom: 1px dotted #85c1f1;
	color: #3d9aa1;
	font-weight: 600;
	cursor: pointer;
}

.main-content a:hover,
.left-sidebar-custom a:hover,
.right-side-col a:hover,
.text-link:hover {
	border-bottom-style: dotted;
	border-bottom-color: transparent;
	color: #1f5390;
}

.footer-left-column-wrap {
	width: 50%;
	min-width: 220px;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.field-label {
	font-weight: 600;
}

.news-content-wrap {
	width: 100%;
	margin-bottom: 1.5rem;
}

.signoff-section {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0vw;
	padding-left: 0vw;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}

.signoff-links-wrap {
	margin-bottom: 0.5rem;
	float: left;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.signoff-link {
	margin-right: 0.5rem;
	border-bottom: 1px dotted hsla(0, 0%, 100%, 0.5);
	opacity: 0.75;
	color: #fff;
	text-decoration: none;
}

.signoff-link:hover {
	border-bottom-color: transparent;
	opacity: 1;
	text-decoration: none;
}

.back-to-top {
	position: fixed;
	right: 0px;
	bottom: 16px;
	z-index: 10000;
	display: inline;
	width: 40px;
	height: 40px;
	margin-right: 1.5rem;
	padding: 1.5rem 1rem 0.5rem;
	float: right;
	border-radius: 50%;
	background-color: #3d9aa1;
	background-image: url('../images/arrow-medium-up_white.svg');
	background-position: 50% 9px;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 0;
}

.back-to-top:hover {
	background-color: #4cbeb8;
	font-size: 0.8rem;
	letter-spacing: 0.03rem;
}

.mobile-search-wrapper {
	display: none;
}

.mobile-search-close {
	display: none;
}

.mobile-search-dd-button {
	display: none;
}

.news-half-wrap .posts-node .posts-field:nth-child(2),
.cb-paragraph {
	margin-top: 0rem;
	margin-bottom: 1rem;
}

.nav-flyout-right {
	position: absolute;
	left: 97%;
	top: 0px;
	display: none;
	width: 100%;
	padding-right: 0.5rem;
	background-color: #20b4b4;
}

.block-quote-link.w--current {
	border-bottom: 1px solid hsla(38, 77%, 77%, 0.5);
	color: #f2d199;
}

.block-quote-link.w--current:hover {
	border-bottom-color: transparent;
	color: #bf935e;
}

.footer-right-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	min-width: 220px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.footer-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1400px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.overlay-2-image-1 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-2-image-2 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-1-image-2 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-2-image-3 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-1-image-3 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-2-image-4 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-1-image-4 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.news-half-wrap .posts-node .posts-field:first-of-type p,
.news-feed-title {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-family: Raleway, sans-serif;
	color: #3d9aa1;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 0rem;
	text-transform: none;
}

.news-half-wrap .posts-node .posts-field:first-of-type p a,
.news-title-link {
	border-bottom: 1px dotted #85c1f1;
	font-weight: 600;
	color: inherit;
}

.news-half-wrap .posts-node .posts-field:first-of-type p a:hover,
.news-title-link:hover {
	border-bottom-color: transparent;
	color: #274c77;
}

.news-half-wrap .posts-node .posts-field:first-of-type p a.w--current,
.news-title-link.w--current {
	color: #1f656e;
}

.news-half-wrap .posts-node .posts-field:last-of-type {
	display: none;
}

.cb-heading-centered {
	display: block;
	margin-top: 0rem;
	font-family: Raleway, sans-serif;
	color: #3d9aa1;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0rem;
}

.cb-header-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row-header-divider {
	width: 100px;
	height: 6px;
	margin-top: 0.5rem;
	margin-bottom: 2.5rem;
	border-bottom: 3px dotted #adadad;
}

.row-header-divider.last-row-header-divider {
	margin-bottom: 2rem;
	border-bottom-width: 3px;
	border-bottom-color: #adadad;
}

.row-header-divider.style-divider {
	display: block;
	width: 100%;
	margin: 2rem auto;
}

.section-wrapper {
	position: relative;
	background-color: #3d9aa1;
}

.section-wrapper.section-2 {
	z-index: 501;
	padding-bottom: 4.5rem;
	box-shadow: inset 0 72px 0 0 #fff;
}

.section-wrapper.section-1 {
	z-index: 500;
	padding-top: 180px;
	background-color: #fff;
}

.section-wrapper.lower-cta {
	background-color: #fff;
}

.top {
	height: 0px;
}

.overlay-2-image-5 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-2-image-6 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(83deg, rgba(0, 0, 0, 0.9), transparent);
	background-image: linear-gradient(7deg, rgba(0, 0, 0, 0.9), transparent);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-1-image-5 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.overlay-1-image-6 {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.top-nav-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding: 0.13rem 0.25rem;
	float: right;
	font-family: Raleway, sans-serif;
	color: #636363;
	font-size: 0.7rem;
	font-weight: 600;
	text-transform: uppercase;
}

.top-nav-link:hover {
	color: #3d9aa1;
}

.has-dropdown:hover .common-link {
	color: #3d9aa1;
}

.top-nav-link.top-nav-main-dd-link {
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 1.5rem;
	padding-left: 0.5rem;
}

.colors {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.color-swatch {
	width: 18%;
	height: 100px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding-left: 0.25rem;
	background-color: #4cbeb8;
}

.color-swatch._29 {
	background-color: #000;
}

.color-swatch._30 {
	background-color: #1f5390;
}

.color-swatch._31 {
	background-color: #3d9aa1;
}

.color-swatch._33 {
	background-color: #adadad;
}

.footer-heading {
	display: block;
	margin-top: 0rem;
	border-bottom: 3px dotted rgba(0, 0, 0, 0.35);
	font-family: Raleway, sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.footer-nav-link {
	display: inline-block;
	padding-right: 0.25rem;
	border-bottom: 1px dotted transparent;
	color: #fff;
	font-size: 0.9rem;
}

.footer-nav-link.active,
.footer-nav-link:hover {
	border-bottom: 1px dotted #b0e9e5;
	color: #b0e9e5;
}

.nav-bar {
	display: block;
	width: 100%;
	margin-top: 3rem;
	margin-right: auto;
	margin-left: auto;
	padding-right: 1rem;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	border-left: 1px solid rgba(0, 0, 0, 0.05);
	background-color: #fff;
	background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, #fff 94%, #faf3f3);
	background-image: radial-gradient(circle farthest-corner at 50% 50%, #fff 94%, #faf3f3);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12), 0 -4px 8px 0 rgba(0, 0, 0, 0.05);
}

.nav-dd-toggle {
	padding: 0px;
}

.nav-dd-toggle.w--open {
	color: #3d9aa1;
}

.icon {
	color: rgba(0, 0, 0, 0.35);
	font-size: 0.75rem;
}

.nav-dd-list.w--open {
	top: 58px;
	padding-top: 4px;
	padding-bottom: 2px;
	background-color: #4cbeb8;
}

.brand-link {
	position: absolute;
	top: -44px;
	margin-left: 1.5rem;
}

.nav-container {
	max-width: 1300px;
}

.main-nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-wrap {
	position: relative;
	top: 0px;
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.news-half-wrap {
	position: relative;
	width: 50%;
	padding-right: 2rem;
	padding-bottom: 4rem;
	padding-left: 2rem;
	border-left: 1px solid #d6d2c6;
}

.news-half-wrap.left-news {
	border-left-style: none;
}

.news-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.news-wrap .node.posts-node {
	padding-top: 0;
	margin-top: 0;
}

.news-half-header {
	display: block;
	margin-top: 0rem;
	color: #1f5390;
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: uppercase;
}

.header {
	padding-bottom: 3.8rem;
	border-top: 20px solid #4cbeb8;
	background-image: none;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 1000;
	position: fixed;
}

.book-widget {
	display: block;
	max-width: 960px;
	margin-right: auto;
	margin-left: auto;
	padding: 0.25rem 2rem 0rem 0.75rem;
	border: 1px solid #d6d2c6;
	background-color: hsla(0, 0%, 100%, 0.7);
	box-shadow: inset 0 4px 11px 0 rgba(0, 0, 0, 0.1);
}

.feed-item {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.feed-book-category {
	display: inline-block;
	margin-right: 0.6rem;
	font-weight: 500;
}

.cb-header-wrap p,
.last-row-paragraph {
	max-width: 960px;
	margin-bottom: 1.5rem;
}

.nav-dd-link {
	margin-top: 0px;
	margin-bottom: 2px;
	font-size: 0.88rem;
	font-weight: 500;
}

.nav-dd-link:hover {
	background-color: hsla(0, 0%, 100%, 0.35);
}

.nav-dd-link.w--current {
	background-color: hsla(0, 0%, 100%, 0.35);
	color: #000;
}

.cb-header-icon-wrap {
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.hero-image-wrap {
	position: relative;
	left: -121px;
	display: none;
	width: 650px;
}

.down-triangle {
	display: block;
	width: 14px;
	height: 10px;
	margin-right: auto;
	margin-bottom: 3.5rem;
	margin-left: auto;
	border-top: 10px solid #4cbeb8;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
}

.quicklinks {
	overflow: hidden;
}

.quicklinks-col {
	padding: 0;
}

.section-1-heading {
	position: relative;
	left: -7px;
	margin-bottom: 1.5rem;
	font-family: 'Playfair Display', sans-serif;
	color: #1f5390;
	font-size: 4.25rem;
	line-height: 4.75rem;
	font-weight: 700;
	letter-spacing: 0rem;
}

.section-1-cb {
	position: relative;
	z-index: 2;
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.section-1-paragraph-wrap {
	display: block;
	width: 50%;
	margin-bottom: 2rem;
	background-color: hsla(0, 0%, 100%, 0.05);
}

.section-1-paragraph-wrap p,
.section-1-paragraph {
	margin-bottom: 0.75rem;
	font-size: 1.2rem;
	line-height: 1.8rem;
}

.section-1-cta-header-wrap {
	padding-left: 2.75rem;
	background-position: 0px 2px;
	background-size: 38px 38px;
	background-repeat: no-repeat;
}

.section-1-cta-heading {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Playfair Display', sans-serif;
	color: #1f5390;
	font-size: 2.15rem;
	line-height: 2.5rem;
	font-weight: 700;
	letter-spacing: 0rem;
}

.section-1-cta-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.section-1-cta-wrap {
	display: block;
	max-width: 550px;
	margin-top: 1.25rem;
	padding: 1.25rem 2rem 1.5rem;
	border: 2px none #e0ded6;
	background-color: hsla(0, 0%, 100%, 0.9);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
	text-align: left;
}

.bg-image {
	position: absolute;
	right: 50%;
	bottom: 0px;
	z-index: 1;
	width: 1100px;
	height: 680px;
	margin-right: -1050px;
	background-image: url('../images/kindle-and-book-tiny.jpg');
	background-position: 0px 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-1-content-wrap {
	width: 100%;
}

.section-3 {
	padding-top: 2rem;
}

.subheadings {
	font-size: 2.5rem;
	font-weight: 600;
}

.subheadings.subheads-green {
	margin-bottom: 1rem;
	color: #3d9aa1;
}

.subheadings.subheads-green._3 {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 3rem;
	font-weight: 500;
}

.comfort {
	position: relative;
	top: -6px;
	bottom: 25px;
	display: inline-block;
	margin-top: -0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 206px;
	clear: left;
	font-size: 3.2rem;
}

.div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 36px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.savvy {
	position: relative;
	bottom: 35px;
	display: block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 30px;
	clear: left;
	color: #3d9aa1;
	font-size: 3.25rem;
}

.body {
	padding-bottom: 82px;
}

.top-nav-dd-toggle {
	padding: 0px;
}

.top-nav-dd-widget {
	float: right;
}

.top-nav-dd-icon {
	margin-right: 0.5rem;
	color: rgba(0, 0, 0, 0.35);
	font-size: 0.75rem;
}

.top-nav-dd-link {
	margin-top: 0px;
	margin-bottom: 2px;
	padding: 6px 15px;
	font-size: 0.83rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.top-nav-dd-link:hover {
	background-color: hsla(0, 0%, 100%, 0.35);
}

.top-nav-dd-link.w--current {
	background-color: hsla(0, 0%, 100%, 0.35);
	color: #222222;
}

.top-nav-dd-list.w--open {
	top: 29px;
	padding-top: 4px;
	padding-bottom: 2px;
	background-color: #4cbeb8;
}

.lower-cta-wrap {
	display: block;
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.25rem 2rem 1.5rem;
	border: 2px none #e0ded6;
	background-color: hsla(0, 0%, 100%, 0.9);
	box-shadow: none;
	text-align: left;
}

.inner-button-wrap {
	width: 47%;
}

.section-1-cta-button-wrap ul,
.lower-cta-button-wrap ul,
.cta-list {
	margin-bottom: 0.5rem;
	padding-left: 23px;
	font-size: 0.9rem;
	line-height: 1.25rem;
}

.double-arrow-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 10px;
	margin-top: 4px;
	margin-left: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.double-arrow {
	width: 10px;
}

.lower-cta-wrap p,
.lower-cta-paragraph {
	font-size: 1rem;
}

.lower-cta-heading {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Playfair Display', sans-serif;
	color: #1f5390;
	font-size: 2.15rem;
	line-height: 2.5rem;
	font-weight: 700;
	letter-spacing: 0rem;
}

.lower-cta-heading-wrap {
	padding-left: 2.75rem;
	background-position: 0px 2px;
	background-size: 38px 38px;
	background-repeat: no-repeat;
}

.lower-cta-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.lower-cta-inner-button-wrap {
	width: 47%;
}

.free-ebook-double-arrow-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 10px;
	margin-top: 4px;
	margin-left: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.heading {
	color: #1f5390;
}

.heading-2 {
	color: #1f5390;
}

.right-col-list {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-col-link {
	border-bottom: 1px dotted #85c1f1;
	color: #3d9aa1;
	font-weight: 600;
}

.right-col-link:hover {
	border-bottom-color: transparent;
	color: #1f5390;
}

.inside-page-wrapper {
	position: relative;
	padding-top: 210px;
}

.inside-content-section {
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.inside-top-white-fixed {
	position: fixed;
	display: block;
	width: 100%;
	height: 100vh;
	border-top: 150px solid #fff;
	background-color: #3d9aa1;
	-webkit-transition: border 200ms ease;
	transition: border 200ms ease;
}

.content-wrapper {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	padding: 0vw 3vw 2.5rem;
	border-top: 4px solid #4cbeb8;
	background-color: #fff;
	box-shadow: 0 -2px 40px 0 rgba(0, 0, 0, 0.2);
}

.content-wrapper.inside-full-content-wrapper {
	padding-right: 8vw;
	padding-left: 8vw;
}

.book-widget .fl-container {
	padding: 7px 0;
}

.book-widget .edit-genres-button,
.book-widget .genre-form,
.book-widget .fl-heading,
.book-widget hr.widget,
.book-widget .fl-copyright,
.book-widget .centered-text {
	display: none;
}

.book-link {
	border-bottom: 1px dotted #85c1f1;
	color: #3d9aa1;
	font-weight: 600;
}

.book-link:hover {
	border-bottom-style: dotted;
	border-bottom-color: transparent;
	color: #1f5390;
}

.page-title-wrap {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 3px dotted #adadad;
}

.inside-page-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.main-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-top: 1.5rem;
	min-height: 300px;
}

.left-nav-wrapper {
	width: 25%;
	height: 100%;
	max-width: none;
	min-width: 180px;
	margin-right: 3vw;
	padding-top: 1.25rem;
}

.left-nav-section-title {
	margin-top: 0.5rem;
	margin-bottom: 0.25rem;
	border-bottom: 1px solid #d6d2c6;
	color: #3d9aa1;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 700;
}

.right-col-list-with-bullets {
	padding-left: 20px;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box,
.right-col-item-wrap {
	margin-bottom: 2rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: transparent;
	font-weight: 400;
}

.left-nav-list.greatgrandchild-nav-list {
	background-color: #f5f5f5;
}

.left-nav-list-item {
	margin-bottom: 0rem;
}

.left-nav-list-item.grandchild-li {
	margin-left: 1rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list-link {
	display: block;
	padding: 0.5rem 0.5rem 0.5rem 0.35rem;
	color: #000;
	font-weight: 400;
}

.left-nav-list-link:hover {
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
	color: #3d9aa1;
}

.left-nav-list-link.selected {
	background-color: rgba(174, 228, 225, 0.9);
}

.left-nav-list-link.selected:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.left-nav-list-link.grandchild-link {
	padding-right: 0.5rem;
	font-size: 0.92rem;
	line-height: 1.32rem;
	font-weight: 400;
}

.left-nav-list-link.grandchild-link.selected {
	background-color: rgba(174, 228, 225, 0.5);
}

.left-nav-list-link.greatgrandchild-link {
	padding-right: 0.5rem;
	padding-left: 0.85rem;
	font-size: 0.82rem;
	line-height: 1.22rem;
	font-weight: 400;
}

.left-nav-list-link.greatgrandchild-link:hover {
	color: #3d9aa1;
}

.left-nav-list-link.greatgrandchild-link.selected {
	padding-left: 0.85rem;
	background-color: transparent;
	background-image: url('../images/angle-double-right.svg');
	background-position: 0px 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
}

.left-nav-link-wrap {
	border-bottom: 1px solid #e0ded6;
}

.breadcrumb-content-wrapper {
	position: relative;
	display: none;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0vw 3vw 1rem;
	background-color: #fff;
}

.modal-popup {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20000;
	display: block;
	width: 100%;
	height: 100%;
	padding-top: 15vh;
}

.popup-box {
	position: relative;
	display: block;
	max-width: 640px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem;
	background-color: #fff;
	box-shadow: 0 6px 40px 3px rgba(0, 0, 0, 0.4);
	border-radius: 0;
}

.link-block {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 40px;
	height: 40px;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	padding: 0.6rem;
}

.highlight {
	background-color: #f8ff5a;
}

html.w-mod-js *[data-ix="hero-heading"] {
	opacity: 0;
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

html.w-mod-js *[data-ix="hero-paragraph-wrap"] {
	opacity: 0;
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

html.w-mod-js *[data-ix="cta-box"] {
	opacity: 0;
	-webkit-transform: translate(600px, 0px);
	-ms-transform: translate(600px, 0px);
	transform: translate(600px, 0px);
}

html.w-mod-js *[data-ix="free-ebook-button-load"] {
	opacity: 0;
	-webkit-transform: translate(600px, 0px);
	-ms-transform: translate(600px, 0px);
	transform: translate(600px, 0px);
}

html.w-mod-js *[data-ix="news-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 150px);
	-ms-transform: translate(0px, 150px);
	transform: translate(0px, 150px);
}

html.w-mod-js *[data-ix="row-1-cb-header-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-1"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-4"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-5"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-1-cb-reveal-6"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-2-cb-reveal-1"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-2-cb-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-2-cb-reveal-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="row-3-cb-reveal"] {
	opacity: 0;
	-webkit-transform: translate(-100px, 0px);
	-ms-transform: translate(-100px, 0px);
	transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="row-3-cb-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(100px, 0px);
	-ms-transform: translate(100px, 0px);
	transform: translate(100px, 0px);
}

html.w-mod-js *[data-ix="slidecaptionheader"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000006, 0.7000000000000006);
	-ms-transform: scale(0.7000000000000006, 0.7000000000000006);
	transform: scale(0.7000000000000006, 0.7000000000000006);
}

html.w-mod-js *[data-ix="slidecaptionheader-2"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000006, 0.7000000000000006);
	-ms-transform: scale(0.7000000000000006, 0.7000000000000006);
	transform: scale(0.7000000000000006, 0.7000000000000006);
}

html.w-mod-js *[data-ix="slide-learn-more"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="testimonial-reveal"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000006, 0.7000000000000006);
	-ms-transform: scale(0.7000000000000006, 0.7000000000000006);
	transform: scale(0.7000000000000006, 0.7000000000000006);
}

html.w-mod-js *[data-ix="testimonial-credits-reveal"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000006, 0.7000000000000006);
	-ms-transform: scale(0.7000000000000006, 0.7000000000000006);
	transform: scale(0.7000000000000006, 0.7000000000000006);
}

html.w-mod-js *[data-ix="header-content-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, -150px);
	-ms-transform: translate(0px, -150px);
	transform: translate(0px, -150px);
}

html.w-mod-js *[data-ix="header-content-load-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, -150px);
	-ms-transform: translate(0px, -150px);
	transform: translate(0px, -150px);
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	h2 {
		margin-bottom: 0.5rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h3 {
		margin-bottom: 0.5rem;
	}

	h5 {
		line-height: 1.5rem;
	}

	h6 {
		line-height: 1.3rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	.button.more-button {
		margin-top: 1rem;
	}

	.button.section-1-cta-button {
		width: 100%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.main-content {
		width: 100%;
	}

	.container-custom {
		max-width: 991px;
	}

	.container-custom.home-container {
		max-width: 991px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container-custom.last-row-container {
		max-width: 860px;
		padding-top: 3rem;
	}

	.container-custom.top-nav-cont {
		display: none;
		width: 75%;
	}

	.container-custom.inside-page-container {
		max-width: 991px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.breadcrumbs-container {
		padding-left: 0rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding: 1rem 0rem 0rem;
		border-top: 3px dotted #adadad;
		background-color: transparent;
	}

	.right-side-col h2 {
		margin-top: 1rem;
	}

	.right-side-col p {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.right-side-col h3 {
		margin-top: 1rem;
	}

	.right-side-col h4 {
		margin-top: 1rem;
	}

	.logo {
		height: 140px;
	}

	.main-nav-link {
		margin-top: 0rem;
		padding: 0.8rem 1rem;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
		color: #000;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: left;
	}

	.main-nav-link:hover {
		background-color: hsla(0, 0%, 100%, 0.35);
		color: #000;
		line-height: 1.5rem;
	}

	.main-nav-link.w--current {
		border-bottom-color: rgba(93, 63, 58, 0.35);
		background-color: hsla(0, 0%, 100%, 0.7);
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
		color: #000;
	}

	.main-nav-link.mobile-duplicate:hover {
		border-style: none;
		border-bottom-color: transparent;
	}

	.home-content-section {
		padding: 2rem 2rem 1rem;
	}

	.home-content-section.row-2-section {
		padding-right: 1rem;
		padding-bottom: 3rem;
		padding-left: 1rem;
	}

	.home-content-section.last-row {
		padding-right: 4rem;
		padding-left: 4rem;
	}

	.home-content-section.row-1-section {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-section.fixed-image {
		padding-right: 4rem;
		padding-left: 4rem;
	}

	.overlay-1-image-1 {
		margin-bottom: 0rem;
	}

	.footer-section {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.copyright-paragraph {
		margin-bottom: 0.25rem;
		float: none;
		text-align: left;
	}

	.social-media-wrapper {
		margin-bottom: 1.25rem;
	}

	.social-media-link-block {
		margin-left: 1rem;
		border-radius: 50%;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block.first-social-link {
		margin-left: 0rem;
		-webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	.social-media-link-block.first-social-link:hover {
		border-radius: 50%;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 2rem 4rem 1.5rem;
	}

	.home-content-wrapper.signoff-wrapper {
		margin-right: 0rem;
		margin-left: 0px;
		padding: 1rem 2rem 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.mobile-menu-icon {
		font-size: 2rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.mobile-menu-arrow {
		display: block;
		width: 15%;
		border-left: 1px solid rgba(93, 63, 58, 0.35);
		background-image: url('../images/chevron-right.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: hsla(0, 0%, 100%, 0.35);
		opacity: 1;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
	}

	.footer-left-column-wrap {
		display: block;
		width: 50%;
		min-width: 0px;
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.news-content-wrap {
		width: 100%;
	}

	.signoff-section {
		width: 100%;
		background-color: transparent;
	}

	.signoff-links-wrap {
		display: inline-block;
		margin-top: 0.25rem;
		margin-bottom: 0.5rem;
		margin-left: 0rem;
		float: none;
	}

	.signoff-link {
		margin-top: 0px;
		margin-right: 1rem;
	}

	.back-to-top {
		display: inline;
	}

	.footer-right-column-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 40%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-content-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.quicklinks-col {
		width: 100%;
		float: none;
	}

	.overlay-2-image-1 {
		margin-bottom: 0rem;
	}

	.overlay-2-image-2 {
		margin-bottom: 0rem;
	}

	.overlay-1-image-2 {
		margin-bottom: 0rem;
	}

	.overlay-2-image-3 {
		margin-bottom: 0rem;
	}

	.overlay-1-image-3 {
		margin-bottom: 0rem;
	}

	.overlay-2-image-4 {
		margin-bottom: 0rem;
	}

	.overlay-1-image-4 {
		margin-bottom: 0rem;
	}

	.cb-heading-centered {
		font-size: 2.25rem;
	}

	.row-header-divider.last-row-header-divider {
		margin-bottom: 1.5rem;
	}

	.section-wrapper.section-2 {
		padding-bottom: 0rem;
	}

	.section-wrapper.section-1 {
		padding-top: 125px;
	}

	.overlay-2-image-5 {
		margin-bottom: 0rem;
	}

	.overlay-2-image-6 {
		margin-bottom: 0rem;
	}

	.overlay-1-image-5 {
		margin-bottom: 0rem;
	}

	.overlay-1-image-6 {
		margin-bottom: 0rem;
	}

	.nav-bar {
		margin-top: 0rem;
		padding-right: 0rem;
		background-image: none;
		box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
	}

	.brand-link {
		margin-top: 1.5rem;
	}

	.nav-container {
		max-width: 991px;
	}

	.main-nav-menu {
		right: -1px;
		width: 70%;
		margin-top: 64px;
		background-color: #4cbeb8;
	}

	.news-half-header {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.header {
		background-image: none;
	}

	.cb-header-wrap p,
	.last-row-paragraph {
		margin-top: 0rem;
		max-width: 100%;
	}

	.hero-image-wrap {
		display: none;
	}

	.down-triangle {
		margin-bottom: 3rem;
	}

	.mobile-menu {
		padding: 0.75rem 1rem;
	}

	.mobile-menu.w--open {
		background-color: transparent;
		color: #000;
	}

	.signoff-info-wrap {
		width: 100%;
		margin-bottom: 1rem;
		text-align: center;
	}

	.section-1-heading {
		left: 0px;
		margin-bottom: 1rem;
		font-size: 3.8rem;
	}

	.section-1-cb {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.section-1-paragraph-wrap {
		width: 100%;
		max-width: 700px;
		margin-bottom: 0rem;
	}

	.section-1-paragraph-wrap p,
	.section-1-paragraph {
		margin-bottom: 0.75rem;
		font-size: 1.15rem;
		line-height: 1.6rem;
	}

	.section-1-cta-header-wrap {
		padding-left: 2.5rem;
		background-size: 32px 32px;
	}

	.section-1-cta-heading {
		font-size: 1.9rem;
		line-height: 2.2rem;
	}

	.section-1-cta-button-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.section-1-cta-wrap {
		display: block;
		width: 70%;
		max-width: none;
		margin-top: 3rem;
	}

	.bg-image {
		height: 545px;
		margin-right: -1025px;
	}

	.lower-cta-wrap {
		display: block;
		max-width: 640px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.lower-cta-heading {
		font-size: 1.9rem;
		line-height: 2.2rem;
	}

	.lower-cta-heading-wrap {
		padding-left: 2.5rem;
		background-size: 32px 32px;
	}

	.lower-cta-button-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.inside-page-wrapper {
		padding-top: 160px;
	}

	.inside-top-white-fixed {
		border-top-width: 85px;
	}

	.content-wrapper {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.content-wrapper.inside-full-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-page-content-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content-wrapper {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.left-nav-wrapper {
		display: none;
	}
}

@media (max-width: 767px) {
	h1 {
		margin-top: 1.25rem;
		font-size: 2.15rem;
		line-height: 2.65rem;
	}

	h2 {
		margin-top: 1.25rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.8rem;
	}

	h5 {
		line-height: 1.5rem;
	}

	h6 {
		line-height: 1.3rem;
	}

	blockquote {
		border-right-width: 6px;
		border-left-width: 6px;
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.button.more-button {
		margin-left: 1rem;
	}

	.button.section-1-cta-button {
		width: 100%;
		padding-right: 0.4rem;
		padding-left: 0.4rem;
		font-size: 0.9rem;
		letter-spacing: 0.01rem;
	}

	.button.todayfree {
		max-width: 325px;
		margin-right: 0rem;
		font-size: 0.9rem;
	}

	.container-custom {
		max-width: 767px;
	}

	.container-custom.home-container {
		max-width: 767px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container-custom.last-row-container {
		max-width: 767px;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.container-custom.inside-page-container {
		max-width: 767px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.breadcrumbs-container {
		font-size: 0.85rem;
	}

	.right-side-col {
		padding-top: 0.5rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
	}

	.home-content-section {
		padding-bottom: 2rem;
	}

	.home-content-section.row-2-section {
		padding-right: 1rem;
		padding-bottom: 2.5rem;
		padding-left: 1rem;
	}

	.home-content-section.last-row {
		padding: 2.5rem 2rem;
	}

	.home-content-section.row-1-section {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.home-content-section.fixed-image {
		padding: 2.5rem 2rem;
	}

	.footer-grip-link {
		display: block;
		margin-right: 0px;
		margin-bottom: 1rem;
		margin-left: 0px;
		float: none;
	}

	.copyright-paragraph {
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		float: none;
		text-align: left;
	}

	.social-media-wrapper {
		margin-bottom: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.signoff-wrapper {
		padding: 1.5rem 0rem 1rem;
	}

	.mobile-menu-arrow {
		width: 20%;
		background-size: 16px 16px;
	}

	.mobile-menu-arrow:hover {
		background-size: 16px 16px;
	}

	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.25rem;
		line-height: 1.9rem;
	}

	.breadcrumbs-list {
		display: inline-block;
		margin-top: 0rem;
	}

	.breadcrumbs-list-item {
		margin-bottom: 0.15rem;
	}

	.footer-left-column-wrap {
		width: 100%;
		margin-bottom: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.signoff-links-wrap {
		margin-bottom: 1rem;
		float: none;
	}

	.signoff-link {
		margin-right: 0.75rem;
		margin-bottom: 0.5rem;
	}

	.footer-right-column-wrap {
		width: 100%;
		min-width: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.cb-heading-centered {
		font-size: 2.25rem;
		max-width: 100%;
	}

	.section-wrapper.section-2 {
		padding-bottom: 0rem;
	}

	.footer-heading {
		width: 100%;
		line-height: 2rem;
	}

	.footer-nav-link {
		margin-bottom: 0.5rem;
		line-height: 1.2rem;
	}

	.nav-container {
		max-width: 767px;
	}

	.news-half-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.news-half-wrap.left-news {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.feed-item {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.feed-book-category {
		display: block;
	}

	.down-triangle {
		margin-bottom: 2.5rem;
	}

	.section-1-heading {
		width: 100%;
		margin-bottom: 0.65rem;
		font-size: 2.8rem;
		line-height: 3.15rem;
	}

	.section-1-cb {
		max-width: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.section-1-paragraph-wrap {
		max-width: 640px;
	}

	.section-1-paragraph-wrap p,
	.section-1-paragraph {
		font-size: 1rem;
	}

	.section-1-cta-button-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.section-1-cta-wrap {
		width: 100%;
		max-width: 520px;
		margin-top: 2.5rem;
	}

	.bg-image {
		width: 800px;
		height: 500px;
		margin-right: -600px;
	}

	.lower-cta-wrap {
		width: 100%;
		max-width: 704px;
	}

	.lower-cta-button-wrap {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.inside-page-wrapper {
		padding-top: 160px;
	}

	.inside-top-white-fixed {
		border-top-width: 75px;
	}

	.content-wrapper {
		padding-right: 1.5rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
	}

	.breadcrumb-content-wrapper {
		padding-bottom: 0.5rem;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.9rem;
		line-height: 2.4rem;
	}

	h2 {
		margin-top: 1.15rem;
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	h3 {
		font-size: 1.15rem;
		line-height: 1.7rem;
	}

	h4 {
		font-size: 1.05rem;
	}

	h5 {
		line-height: 1.5rem;
	}

	h6 {
		line-height: 1.3rem;
	}

	blockquote {
		border-right-width: 6px;
		border-left-width: 6px;
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.button.more-button {
		position: relative;
		display: block;
		width: 100%;
		margin-top: 0.5rem;
		margin-right: auto;
		margin-left: auto;
	}

	.button.todayfree {
		height: auto;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container-custom {
		max-width: 479px;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container-custom.home-container {
		max-width: none;
	}

	.container-custom.last-row-container {
		max-width: none;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.logo {
		height: 130px;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.home-content-section {
		padding-bottom: 2rem;
	}

	.home-content-section.last-row {
		padding-top: 1.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.home-content-section.row-1-section {
		height: auto;
		padding-right: 1.5rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
	}

	.footer-grip-link {
		display: block;
		margin-top: 0px;
	}

	.copyright-paragraph {
		margin-right: 0.25rem;
		margin-bottom: 0rem;
		text-align: center;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.home-content-wrapper.signoff-wrapper {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.15rem;
		line-height: 1.8rem;
	}

	.signoff-links-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.signoff-link {
		display: inline-block;
	}

	.mobile-search-wrapper {
		display: none;
		width: 100%;
		height: 0px;
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: rgba(27, 165, 165, 0.45);
		opacity: 0;
	}

	.mobile-search-close {
		display: none;
		width: 45px;
		height: 40px;
		min-width: 40px;
		padding: 4px 8px;
		background-color: #1ba5a5;
		background-image: url('../images/close_x_black.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		opacity: 0;
	}

	.mobile-search-dd-button {
		display: block;
		width: 45px;
		height: 40px;
		padding: 4px 8px;
		background-image: url('../images/search_blk.svg');
		background-position: 50% 50%;
		background-size: 18px 18px;
		background-repeat: no-repeat;
	}

	.mobile-search-dd-button:hover {
		background-color: #1ba5a5;
	}

	.cb-heading-centered.longer-cb-heading {
		font-size: 2rem;
	}

	.row-header-divider {
		margin-bottom: 1.5rem;
	}

	.section-wrapper.section-1 {
		padding-top: 120px;
	}

	.footer-heading {
		border-bottom-width: 3px;
		line-height: 2rem;
	}

	.footer-nav-link {
		margin-bottom: 0.5rem;
		line-height: 1.2rem;
	}

	.brand-link {
		margin-left: 0.5rem;
	}

	.main-nav-menu {
		width: 85%;
	}

	.news-half-wrap {
		width: 100%;
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
		border-left-style: none;
	}

	.news-half-wrap.left-news {
		width: 100%;
		margin-bottom: 2rem;
		padding-right: 0rem;
		padding-bottom: 1rem;
		padding-left: 0rem;
		border-bottom: 3px dotted #adadad;
	}

	.news-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.header {
		padding-bottom: 3.75rem;
	}

	.section-1-heading {
		font-size: 2.5rem;
		line-height: 2.9rem;
	}

	.section-1-cb {
		height: 100%;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.section-1-cta-header-wrap {
		padding-left: 0rem;
		background-image: none !important;
	}

	.section-1-cta-button-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.section-1-cta-wrap {
		max-width: none;
		margin-top: 1.5rem;
		margin-right: 0px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
	}

	.bg-image {
		bottom: 20px;
		width: 700px;
		height: 380px;
		margin-right: -388px;
	}

	.lower-cta-wrap {
		max-width: none;
		margin-top: 1.5rem;
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.inner-button-wrap {
		width: 100%;
		margin-bottom: 0.5rem;
	}

	.lower-cta-heading-wrap {
		padding-left: 0rem;
		background-image: none !important;
	}

	.lower-cta-button-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.lower-cta-inner-button-wrap {
		width: 100%;
	}

	.cta-button-text.todayfree-button {
		width: 100%;
	}

	.inside-page-wrapper {
		padding-top: 146px;
	}

	.inside-content-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.inside-top-white-fixed {
		border-top-width: 71px;
	}

	.content-wrapper {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.breadcrumb-content-wrapper {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

html {
	transition: opacity 0.15s ease-out;
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/* --- KEEP MAIN NAV HOVER OPEN (I don't think this is working) --- */
/* Requires js below */
.hovered {
	color: #3d9aa1 !important;
}

/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #ffffff;
	text-decoration: none;
}

/* Mozilla based browsers */
::-moz-selection {
	background-color: #3d9aa1;
	color: #ffffff;
}

/* Safari browser */
::selection {
	background-color: #3d9aa1;
	color: #ffffff;
}

/* MOVE RIGHT COLUMN UNDER MAIN CONTENT ON NARROWER DESKTOPS */
@media (min-width: 992px) and (max-width: 1150px) {
	.breadcrumb-content-wrapper {
		padding-right: 1rem;
	}

	.left-nav-wrapper {
		width: 30%;
	}

	.container-custom.inside-page-container {
		max-width: 1150px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		display: block;
	}

	.main-content {
		width: 100%;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		margin-bottom: 0rem;
		padding: 1rem 0rem 0rem;
		border-top: 3px dotted #adadad;
		background-color: transparent;
	}

	.right-side-col h2 {
		margin-top: 1rem;
	}
}

/*mmenu*/
.header.sticky-header.mm-slideout {
	z-index: 1;
}

.mobile-navigation-menu {
	background-color: #4cbeb8;
	display: none !important;
}

.mobile-navigation-menu.mm-opened {
	display: block !important;
}

.mobile-navigation-menu .mm-panels > .mm-panel > .nav-menu {
	display: block;
	width: 100%;
	top: 0;
	margin: 0;
}

.mobile-navigation-menu .main-nav-dropdown {
	display: block;
}

.mm-listview > li .mm-next:before,
.mm-listview > li:after {
	border-color: transparent;
}

.mm-listview > li .mm-next {
	border-color: rgba(93, 63, 58, 0.35);
	border-left-width: 1px;
	border-left-style: solid;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.mobile-navigation-menu .main-nav-link {
	padding: 0.8rem 1rem;
	color: #000;
	text-transform: capitalize;
}

.mobile-navigation-menu .main-nav-link:hover {
	background-color: transparent;
	color: #000;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	color: #000;
	background-color: hsla(0, 0%, 100%, 0.7);
}

.mm-menu .mm-btn::before,
.mm-menu .mm-listview > li .mm-next::after {
	border-color: #000;
}

.mm-panels > .mm-panel.mm-hasnavbar {
	padding-top: 51px;
}

.mm-navbar {
	border-bottom: 1px solid rgba(93, 63, 58, 0.35);
	height: 51px;
	padding: 0 1.75rem 0 40px;
}

.mm-navbar > * {
	padding: 15px 0;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #000;
	text-transform: capitalize;
	text-align: left;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.mm-btn {
	height: 51px;
}

.mm-prev::before {
	left: 20px;
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 1106px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 735px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/*inside page*/
/*left column*/
.left-sidebar-custom {
	margin-top: 30px;
}

.left-sidebar-custom .box {
	margin-bottom: 25px;
}

.left-sidebar-custom h1,
.left-sidebar-custom h2,
.left-sidebar-custom h3,
.left-sidebar-custom h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #3d9aa1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

/*right column*/
.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}

/*messages*/
#session-message-wrapper .success {
	display: block;
	text-align: left;
	padding: 1rem 1rem 1rem 1.25rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-left: 6px solid #25aaa3;
	background-color: rgba(17, 185, 165, 0.25);
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .error {
	display: block;
	text-align: left;
	padding: 1rem 1rem 1rem 1.25rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-bottom: 0;
	margin-top: 0;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled.tablesaw tr {
	border-bottom: 0 none;
}

table.styled th,
table.styled td {
	border-right: 1px solid #1c8790;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #1c8790;
	color: #ffffff;
}

table.styled th {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #4cbeb8;
	color: #000000;
}

table.styled th a {
	border-bottom: 0 none;
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ddd;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

/*tablesaw*/
@media (max-width: 39.9375em) {
	table.tablesaw.styled th,
	table.tablesaw.styled td {
		border-right: 0 none;
	}

	table.tablesaw.styled {
		border-top: 1px solid #dfdfdf;
	}

	table.tablesaw.styled tbody tr {
		border: 1px solid #dfdfdf;
	}

	.tablesaw-stack td .tablesaw-cell-label,
	.tablesaw-stack th .tablesaw-cell-label {
		width: 46% !important;
	}

	.tablesaw-cell-content {
		max-width: 54% !important;
	}
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-size: 1rem;
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	color: #333333;
	border-radius: 0;
}

textarea {
	resize: vertical;
}

/*strong*/
strong {
	font-weight: 600;
}

/*blockquotes*/
blockquote p {
	margin-bottom: 0;
	margin-top: 0;
	font-size: inherit !important;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

/*horizontal ruler*/
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #d6d6d6 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-color: #d6d6d6;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #d6d6d6;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #d6d6d6;
	border: 0px;
	color: #d6d6d6;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*header links*/
.inside-page-content-wrapper h1 a,
.inside-page-content-wrapper h2 a,
.inside-page-content-wrapper h3 a,
.inside-page-content-wrapper h4 a,
.inside-page-content-wrapper h5 a,
.inside-page-content-wrapper h6 a {
	line-height: 1.3em;
}

.inside-page-content-wrapper h1 a:hover,
.inside-page-content-wrapper h2 a:hover,
.inside-page-content-wrapper h3 a:hover,
.inside-page-content-wrapper h4 a:hover,
.inside-page-content-wrapper h5 a:hover,
.inside-page-content-wrapper h6 a:hover {
	background-color: hsla(0, 0%, 68%, 0.3);
}

/*module styling*/
/* dates */
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
.posts-node .posts-field.posts-field-posted p {
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	color: #000;
	font-family: Raleway, sans-serif;
}

/*ui*/
.ui-form-error {
	display: none;
}

/*required symbol*/
em.required_information,
.ui-form-field.required .ui-form-label em,
#posts_module.form .required_message span:before,
#posts_module.form .form_item .required .ui-form-label em,
#posts_module.form .form_item .required .ui-form-label label:after,
#submissionforms_module.cms_form .cms_required em,
#submissionforms_module tr[data-required="true"] .cms_label::after {
	font-family: sans-serif;
}

/****************** Accounts ************************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account-module.form .form_subheading,
#account_module.form .form_subheading {
	border-bottom: 1px solid #d6d6d6;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #1f5390;
	font-size: 1.85rem;
	line-height: 2.35rem;
	font-weight: 600;
	font-family: Raleway, sans-serif;
}

/*edit address form*/
.w-col.w-col-12.have-account-column {
	padding: 0;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields input[type="password"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}

	td.no-content {
		display: none !important;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding-top: 0;
	margin-bottom: 20px;
}

#faqs_module.cms_list .cms_list_item .description p,
#faqs_module.cms_list .cms_list_subitem .description p {
	margin: 0;
}

.box.tools form > p {
	margin: 0;
}

/******************* Posts *********************/
/***********************************************/
.shareThis-wrapper {
	text-align: right;
	margin-bottom: 20px;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #ccc;
	color: #000;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #d6d6d6;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

#posts_module.form .ui-form-buttons {
	text-align: left;
}

/* sidebar posts */
.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding-top: 9px;
	border: 0 none !important;
	max-height: 41px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button:hover {
	background-color: #1f5390 !important;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.right-side-col .box.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.right-side-col .box.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a,
.right-side-col .box.posts-blocks.posts-blocks-tags .content a {
	border-bottom: 0px;
}

.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category {
	padding-bottom: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

@media (max-width: 767px) {
	.shareThis-wrapper {
		text-align: left;
	}
}

/****************** RSS Feeds *********************/
/*************************************************/
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

/*************** Submission Forms ***************/
/***********************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	left: 5px;
	top: 5px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

/*form with payment*/
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 27%;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"],
#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="tel"],
#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="email"] {
	width: 100%;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #1f5390;
	font-size: 1.35rem;
	line-height: 1.9rem;
	font-weight: 600;
}

.module-forms.confirmation .label-cell {
	font-weight: 600;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 35%;
		left: 5px;
	}
}

/*dynamic content boxes*/
/*posts with image*/
.posts-collection.homepage-contentbox.article-items {
	margin-bottom: 0;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding: 0;
}

.posts-collection.homepage-contentbox.article-items .article-title {
	margin-top: 10px;
	margin-bottom: 0;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	margin-top: 0;
	margin-bottom: 10px;
}

.posts-collection.homepage-contentbox.article-items .article-abstract {
	margin-top: 0;
	margin-bottom: 20px;
}

.posts-collection.homepage-contentbox.article-items .article-image-link {
	display: inline-block;
	max-width: 100px;
	float: right;
	margin-top: 13px;
}

.posts-homepage-block.button.primary {
	display: none;
}

@media (max-width: 991px) {
	.posts-collection.homepage-contentbox.article-items .article-content {
		float: none;
		padding-left: 0;
		width: 100%;
	}

	.posts-collection.homepage-contentbox.article-items .article {
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.posts-collection.homepage-contentbox.article-items .article-image-link {
		float: none;
		display: block;
		margin-top: 0;
		margin-left: 0;
	}
}

/*RSS feed*/
#feeds_module.homepage_contentbox .item {
	background-position: 0px 7px;
	margin-bottom: 15px;
}

#feeds_module.homepage_contentbox .item a {
	border-bottom: 1px dotted #85c1f1;
	font-weight: 600;
	font-family: Raleway, sans-serif;
	color: #3d9aa1;
	font-size: 1.25rem;
	line-height: 1.75rem;
	letter-spacing: 0rem;
	text-transform: none;
}

#feeds_module.homepage_contentbox .item a:hover {
	border-bottom-color: transparent;
	color: #274c77;
}

#feeds_module.homepage_contentbox .item .date-published {
	font-size: 0.85em;
	line-height: 1.6rem;
	font-family: Raleway, sans-serif;
}

#feeds_module.homepage_contentbox .view-feed-page {
	display: none;
}

/*submission forms*/
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox .required_information em,
#submissionforms_module.homepage_contentbox .cms_label em {
	font-family: sans-serif;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .cms_hint {
	color: #666666;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/*subscribe modal*/
.modal-popup.modal.fade {
	display: none;
}

.modal-popup.modal.fade.in {
	display: block;
}

.modal-dialog {
	height: 450px;
}

.modal-content {
	height: 100%;
	overflow-y: scroll;
}

.modal-close {
	background-color: transparent;
}

.modal-content .indicates-required {
	text-align: right;
	margin-bottom: 5px;
}

.modal-content .mc-field-group > label .asterisk,
.modal-content .indicates-required .asterisk {
	font-family: sans-serif;
	color: #ff0000;
}

.modal-content .mc-field-group {
	margin-bottom: 10px;
}

.modal-content .mc-field-group > strong,
.modal-content .mc-field-group > label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.modal-content .mc-field-group ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

.modal-content .mc-field-group ul li label {
	padding-left: 5px;
	vertical-align: top;
}
